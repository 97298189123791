<template>
  <div>
    <h5 class="mb-3 fw-bold">Translate Question and Answer</h5>

    <form @submit.prevent="onSubmit" class="grid-two-questions">
      <div class="mb-3">
        <label for="task" class="form-label">Task</label>
        <select v-model="taskId" class="form-select" required disabled>
          <option v-for="task in tasks" :key="task.id" :value="task.id">{{task.day}} - {{task.task}}</option>
        </select>
      </div>

      <div class="mb-3 col-start-1">
        <label for="task" class="form-label">Base Language</label>
        <select :value="en.id" class="form-select" required disabled>
          <option
            :value="en.id"
          >{{en.short}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="task" class="form-label">User Language</label>
        <select :value="userLang.id" class="form-select" required disabled>
          <option
            :value="userLang.id"
          >{{userLang.short}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="question-en" class="form-label">Base Question</label>
        <textarea v-model="questionTextEN" :disabled="!question.permissions?.userCanEditQuestion?.EN" type="text" class="form-control" id="question-en"></textarea>
      </div>

      <div class="mb-3">
        <label for="question-user-lang" class="form-label">User Question</label>
        <textarea v-model="questionTextUserLang" :disabled="!(userLang.short && question.permissions?.userCanEditQuestion?.[userLang.short])" type="text" class="form-control" id="question-user-lang"></textarea>
      </div>

      <div class="mb-3">
        <label for="answer-en" class="form-label">Base Answer</label>
        <textarea v-model="answerTextEN" :disabled="!question.permissions?.userCanEditAnswer?.EN" type="text" class="form-control" id="answer-en"></textarea>
      </div>

      <div class="mb-3">
        <label for="answer-user-lang" class="form-label">User Answer</label>
        <textarea v-model="answerTextUserLang" :disabled="!(userLang.short && question.permissions?.userCanEditAnswer?.[userLang.short])" type="text" class="form-control" id="answer-user-lang"></textarea>
      </div>

      <div class="col-span-full">
        <button type="submit" class="btn btn-primary">Submit</button>

        <p v-if="state === 'success'" class="mt-3 text-success">Your question has been saved.</p>
        <p v-else-if="state" class="mt-3 text-danger">{{ state }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import {ref} from "vue";
import {user} from "@/lib/user";
import fetcher from '@/lib/fetcher'

export default {
  props: {
    tasks: Array,
    languages: Array,
    question: Object,
  },

  emits: ['submitted'],

  setup(props, { emit }) {
    const taskId = ref(props.question.task_id)
    const en = ref(props.languages.find(l => l.short === 'EN'))
    const questionTextEN = ref(props.question.question[en.value.short])
    const answerTextEN = ref(props.question.answer[en.value.short] ?? '')

    const userLang = ref(user.value.language ?? en)
    const questionTextUserLang = ref(props.question.question[userLang.value.short] ?? '')
    const answerTextUserLang = ref(props.question.answer[userLang.value.short] ?? '')

    const state = ref(null)

    async function onSubmit() {
      await fetcher('/sanctum/csrf-cookie')

      const body = {
          question: {
            EN: questionTextEN.value,
          },
          answer: {
            EN: answerTextEN.value,
          },
      }

      if (userLang.value?.short) {
        body.question[userLang.value.short] = questionTextUserLang.value
        body.answer[userLang.value.short] = answerTextUserLang.value
      }

      const res = await fetcher(`/api/tasks/${props.question.task_id}/questions/${props.question.id}/translate`, {
        method: 'POST',
        body: JSON.stringify(body)
      }).catch(() => state.value = 'An error occurred. Please try again.')

      if (res.ok) {
        state.value = 'success'
        setTimeout(() => state.value = null, 3000)

        emit('submitted', res.data.data)
      } else {
        state.value = res.data.message
      }
    }

    return {
      taskId,
      en,
      questionTextEN,
      answerTextEN,
      userLang,
      questionTextUserLang,
      answerTextUserLang,

      state,

      onSubmit,

      user,
    }
  }
}
</script>

<style scoped>
.grid-two-questions {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
}
@media (min-width: 768px) {
  .grid-two-questions {
    grid-template-columns: 1fr 1fr;
  }
}

.col-span-full {
  grid-column-start: 1;
  grid-column-end: -1;
}
.col-start-1 {
  grid-column-start: 1;
}
</style>

<template>
  <div>
    <h5 class="mb-3 fw-bold">Questions per Task</h5>

    <canvas ref="canvas" width="500" height="300"></canvas>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";

export default {
  props: {
    tasks: Array,
  },

  setup(props) {
    const canvas = ref()

    const mappedValues = computed(() => props.tasks.map(task => ({
      x: `${task.day} - ${task.task}`,
      y: task.questions?.length ?? 0,
    })))

    function updateCanvas() {
      if (!canvas.value) return

      /** @type {CanvasRenderingContext2D} */
      const ctx = canvas.value.getContext('2d')

      const values = mappedValues.value
      const yMax = values.reduce((acc, item) => item.y > acc ? item.y : acc, 0)
      const yStep = 300 / (yMax + 1)

      ctx.lineWidth = 1
      ctx.strokeStyle = 'gray'
      ctx.beginPath()
      ctx.moveTo(20, 0)
      ctx.lineTo(20, 299)
      ctx.lineTo(500, 299)
      ctx.stroke()

      for (let i = 0; i <= yMax; i++) {
        ctx.save()

        ctx.lineWidth = 1
        ctx.strokeStyle = 'lightgray'

        ctx.translate(0, 300 - i * yStep)

        if (i > 0) {
          ctx.beginPath()
          ctx.moveTo(20, 0)
          ctx.lineTo(500, 0)
          ctx.stroke()
        }

        ctx.textAlign = 'end'
        ctx.textBaseline = i === 0 ? 'bottom' : 'middle'
        ctx.fillText(i, 15, 0)

        ctx.restore()
      }

      const colGap = 10
      for (let i in values) {
        const value = values[i]

        ctx.save()

        ctx.fillStyle = '#0084AD'

        ctx.translate(20 + colGap + (500 - colGap * 3) / values.length * i, 300)

        ctx.beginPath()
        ctx.moveTo(0, 1)
        ctx.lineTo(0, -1 * yStep * value.y)
        ctx.lineTo((500 - colGap * 3) / values.length - colGap, -1 * yStep * value.y)
        ctx.lineTo((500 - colGap * 3) / values.length - colGap, 1)
        ctx.closePath()
        ctx.fill()

        ctx.save()

        ctx.rotate(-Math.PI / 2)

        ctx.fillStyle = 'black'
        ctx.fillText(value.x, 10, ((500 - colGap * 3) / values.length - colGap) / 2, 290)

        ctx.restore()

        ctx.restore()
      }
    }

    onMounted(updateCanvas)
    watch(() => props.tasks, updateCanvas, { deep: true })

    return {
      canvas
    }
  },
}
</script>

<style scoped>
canvas {
  width: 500px;
  max-width: 100%;
}
</style>

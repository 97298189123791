<template>
  <Navigation />

  <main class="page-wrapper d-flex flex-column">
    <Messages />

    <Login v-if="!isLoggedIn" />
    <Dashboard v-else />
  </main>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Messages from './components/Messages.vue'
import Login from './components/Login.vue'
import Dashboard from './components/Dashboard.vue'
import {isLoggedIn, setUser} from './lib/user'
import {onMounted} from "vue";
import fetcher from "@/lib/fetcher";

export default {
  name: 'App',
  components: {
    Navigation,
    Messages,
    Login,
    Dashboard,
  },

  setup() {
    onMounted(async () => {
      await fetcher('/sanctum/csrf-cookie')

      const {data} = await fetcher('/api/user')

      setUser(data?.data ?? null)
    })

    return {
      isLoggedIn: isLoggedIn
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #003764;

  max-width: 100vw;
  overflow: hidden;
}

.page-wrapper {
  min-height: 100vh;
  padding-top: 60px;
}
</style>

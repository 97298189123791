<template>
  <div>
    <h5 class="mb-3 fw-bold">Submit New Question</h5>

    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <label for="task" class="form-label">Task</label>
        <select v-model="taskId" class="form-select" required>
          <option v-for="task in tasks" :key="task.id" :value="task.id">{{task.day}} - {{task.task}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="task" class="form-label">Language</label>
        <select :value="languageId" class="form-select" required disabled>
          <option
            v-for="language in languages"
            :key="language.id"
            :value="language.id"
          >{{language.short}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="question" class="form-label">Question</label>
        <textarea v-model="question" type="text" class="form-control" id="question" required></textarea>
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>

      <p v-if="state === 'success'" class="mt-3 text-success">Your question has been saved.</p>
      <p v-else-if="state" class="mt-3 text-danger">{{ state }}</p>
    </form>
  </div>
</template>

<script>
import {ref} from "vue";
import {user} from "@/lib/user";
import fetcher from "@/lib/fetcher";

export default {
  props: {
    tasks: Array,
    languages: Array,
  },

  emits: ['submitted'],

  setup(props, { emit }) {
    const taskId = ref()
    const languageId = ref(user.value.language?.id ?? props.languages.find(l => l.short === 'EN')?.id)
    const question = ref('')
    const state = ref(null)

    async function onSubmit() {
      await fetcher('/sanctum/csrf-cookie')

      const res = await fetcher(`/api/tasks/${taskId.value}/questions`, {
        method: 'POST',
        body: JSON.stringify({
          question: question.value,
        })
      }).catch(() => state.value = 'An error occurred. Please try again.')

      if (res.ok) {
        question.value = ''

        state.value = 'success'
        setTimeout(() => state.value = null, 3000)

        emit('submitted', res.data.data)
      } else {
        state.value = res.data.message
      }
    }

    return {
      taskId,
      languageId,
      question,
      state,

      onSubmit,

      user,
    }
  }
}
</script>

<style scoped></style>

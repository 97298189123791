export const API_URL = 'http://localhost'

export default function fetcher(
  path,
  {
    headers,
    ...options
  } = {}) {
  const config = {
    method: 'GET',
    credentials: 'include',

    headers: {
      credentials: 'include',
      Accept: 'application/json',
      ...headers,
    },

    ...options,
  }

  if (config.body && typeof config.body === 'string') {
    config.headers['Content-Type'] = 'application/json'
  }

  return fetch(API_URL + path, config)
    .then(async res => {
      if (res.headers.get('Content-Type') === 'application/json') {
        res.data = await res.json()
      }
      return res
    })
}

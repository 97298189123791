import {computed, ref} from "vue";

let internalUser = ref(null)
// let internalUser = ref({
//   "id": 3,
//   "username": "competitor1",
//   "language": null,
//   "role": "competitor",
//   "name": null,
//   "email": null
// })

export function setUser(user) {
  internalUser.value = user
}

export const isLoggedIn = computed(() => !!internalUser.value)

export const user = computed(() => internalUser.value)

<template>
  <div class="container-fluid">
    <div
      v-for="(message, i) in messages"
      :key="i"
      class="alert alert-primary mb-0 mt-2"
      role="alert"
    >
      {{ message.text }}
    </div>
  </div>
</template>

<script>
import {watch} from "vue";
import {user} from "../lib/user";
import {messages, fetchMessages} from "../lib/messages";

export default {
  setup() {
    watch(
      () => user.value,
      () => fetchMessages()
    )

    return {
      user,
      messages,
    }
  }
}
</script>

<style scoped></style>

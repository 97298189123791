<template>
  <tr
    class="cursor-pointer"
    :class="{
      highlight: highlighted,
      'border-top border-x': isOpen,
    }"
    tabindex="0"
    @click="isOpen ? $emit('close') : $emit('open')"
    @keypress.prevent.space="isOpen ? $emit('close') : $emit('open')"
    @keypress.prevent.enter="isOpen ? $emit('close') : $emit('open')"
  >
    <td>{{day}}</td>
    <td>{{task}}</td>
    <td>
      <div v-if="question" class="d-inline-flex align-items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          :class="['chevron', { open: isOpen }]"
        ><path d="M20.915,1,10.957,9.611,1,1" transform="translate(1.042 6.695)" fill="none" stroke="currentColor" stroke-width="2"/></svg>

        <div class="ms-2">EN</div>
      </div>
    </td>
    <td>{{question?.question?.EN}}</td>
    <td>{{question?.answer?.EN}}</td>
    <td>{{question?.user?.country}}</td>
  </tr>

  <template v-if="isOpen">
    <tr
      v-for="(language, i) in languages.filter(l => l.short !== 'EN')"
      :key="language.id"
      :class="{
        highlight: highlighted && user.language?.id && language.id === user.language.id,
        'border-x': isOpen,
        'border-bottom': isOpen && i === languages.filter(l => l.short !== 'EN').length - 1,
      }"
    >
      <td></td>
      <td></td>
      <td class="pl-8">{{ language.short }}</td>
      <td>{{question?.question?.[language.short]}}</td>
      <td>{{question?.answer?.[language.short]}}</td>
      <td></td>
    </tr>
  </template>
</template>

<script>
import {user} from "../lib/user";
import {computed} from "vue";
import {messages} from "../lib/messages";

export default {
  props: {
    day: String,
    task: String,
    question: Object,
    isOpen: Boolean,
    languages: Array,
  },

  emits: ['open', 'close'],

  setup(props) {
    const highlighted = computed(() => {
      if (!Array.isArray(messages.value)) {
        return false
      }

      if (!props.question) {
        return false
      }

      return !!messages.value.find(
        m =>
          Array.isArray(m.question_ids) && m.question_ids.includes(props.question.id)
      )
    })

    return {
      user,
      highlighted,
    }
  }
}
</script>

<style scoped>
.chevron {
  width: 16px;
  transition: all 150ms ease;
  transform: rotate(-90deg);
  margin-top: 3px;
}
.chevron.open {
  width: 16px;
  transform: rotate(0deg);
}

tr.highlight {
  background-color: rgb(var(--bs-danger-rgb), 0.2);
}

td:nth-child(1),
td:nth-child(2),
td:nth-child(3) {
  white-space: nowrap;
}

td:nth-child(3) {
  text-align: right;
}
td:nth-child(4),
td:nth-child(5) {
  min-width: min(calc(100vw - 2 * 12px), 20rem);
}

.pl-8 {
  padding-left: 32px;
}

.cursor-pointer {
  cursor: pointer;
}

.border-top {
  border-top: solid 2px #0084AD !important;
}
.border-bottom {
  border-bottom: solid 2px #0084AD !important;
}
.border-x {
  border-left: solid 2px #0084AD !important;
  border-right: solid 2px #0084AD !important;
}
</style>

<template>
  <div class="flex-grow-1 py-5">
    <div class="container-fluid">
      <h1 class="">Questions and Answers</h1>

      <section class="section">
        <h5 class="mb-3 fw-bold">Submitted Questions and Answers</h5>
        <div class="table-wrapper">
          <table class="qna-table table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Day</th>
                <th scope="col">Task</th>
                <th scope="col"></th>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
                <th scope="col">Country</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="task in tasks" :key="task.id">
                <QuestionRow
                  :day="task.day"
                  :task="task.task"
                  :question="task.firstQuestion"
                  :is-open="openQuestionId && openQuestionId === task.firstQuestion?.id"
                  :languages="languages"
                  @open="openQuestionId = task.firstQuestion?.id"
                  @close="openQuestionId = null"
                />

                <template v-for="question in task.otherQuestions" :key="question.id">
                  <QuestionRow
                    :question="question"
                    :is-open="openQuestionId && openQuestionId === question.id"
                    :languages="languages"
                    @open="openQuestionId = question.id"
                    @close="openQuestionId = null"
                  />
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </section>

      <section v-if="user.role === 'competitor'" class="section">
        <NewQuestionForm
          :tasks="tasks"
          :languages="languages"
          @submitted="
            q => {
              addQuestion(q)
              openQuestionId = q.id
            }
          "
        />
      </section>

      <section v-if="user.role === 'expert' && openQuestionId" class="section" :key="openQuestionId">
        <UpdateQuestionAnswerForm
          :tasks="tasks"
          :languages="languages"
          :question="openQuestion"
          @submitted="q => updateQuestion(q)"
        />
      </section>

      <section v-if="user.role === 'translator' && openQuestionId" class="section" :key="openQuestionId">
        <TranslateForm
          :tasks="tasks"
          :languages="languages"
          :question="openQuestion"
          @submitted="q => updateQuestion(q)"
        />
      </section>

      <section v-if="user.role === 'expert'" class="section" key="stats">
        <Stats
          :tasks="tasks"
        />
      </section>

      <section v-if="user.role === 'admin'" class="section" key="admin">
        <AdminSection @submitted="reload()" />
      </section>
    </div>
  </div>
</template>

<script>
import fetcher from "@/lib/fetcher";
import {computed, ref} from "vue";
import QuestionRow from "@/components/QuestionRow";
import NewQuestionForm from "@/components/NewQuestionForm";
import Stats from "@/components/Stats";
import {user} from "@/lib/user";
import UpdateQuestionAnswerForm from "@/components/UpdateQuestionAnswerForm";
import TranslateForm from "@/components/TranslateForm";
import AdminSection from "./AdminSection";
import {fetchMessages} from "../lib/messages";

export default {
  name: 'Dashboard',

  components: {
    AdminSection,
    UpdateQuestionAnswerForm,
    TranslateForm,
    NewQuestionForm,
    QuestionRow,
    Stats,
  },

  setup() {
    // const languages = ref([
    //   {
    //     "id": 1,
    //     "short": "EN"
    //   },
    //   {
    //     "id": 2,
    //     "short": "DE"
    //   },
    //   {
    //     "id": 3,
    //     "short": "FR"
    //   }
    // ])
    // const tasks = ref([
    //   {
    //     "id": 1,
    //     "day": "Day 1",
    //     "task": "Task 1",
    //     "questions": [
    //       {
    //         "id": 7,
    //         "question": {
    //           "EN": "Hic reprehenderit maxime deleniti sint doloremque earum.",
    //           "DE": "Accusamus cupiditate aliquam beatae."
    //         },
    //         "answer": {
    //           "EN": "Earum voluptate aut iusto.",
    //           "DE": "Delectus deserunt commodi nostrum."
    //         }
    //       },
    //       {
    //         "id": 10,
    //         "question": {
    //           "EN": "Suscipit dolores qui magnam veritatis.",
    //           "DE": "Earum ipsa eos cumque facilis ipsa vel facere."
    //         },
    //         "answer": {
    //           "EN": "Sed deserunt molestiae vitae perferendis.",
    //           "DE": "Accusamus sit veritatis odit minima ipsa."
    //         }
    //       }
    //     ]
    //   },
    //   {
    //     "id": 2,
    //     "day": "Day 1",
    //     "task": "Task 2",
    //     "questions": [
    //       {
    //         "id": 8,
    //         "question": {
    //           "EN": "Autem tenetur esse est quibusdam.",
    //           "DE": "Saepe eligendi suscipit a id."
    //         },
    //         "answer": {
    //           "EN": "Consequuntur perferendis minima voluptatibus nobis omnis delectus.",
    //           "DE": "Est libero assumenda quo est."
    //         }
    //       },
    //       {
    //         "id": 11,
    //         "question": {
    //           "EN": "Nisi omnis quasi vel ab enim alias.",
    //           "DE": "Qui et vitae ab iure."
    //         },
    //         "answer": {
    //           "EN": "Ratione modi possimus est est.",
    //           "DE": "Id doloremque molestias tenetur temporibus saepe quo saepe corporis."
    //         }
    //       }
    //     ]
    //   },
    //   {
    //     "id": 3,
    //     "day": "Day 2",
    //     "task": "Task 1",
    //     "questions": [
    //       {
    //         "id": 9,
    //         "question": {
    //           "EN": "Architecto sed nostrum tenetur in officia.",
    //           "DE": "Harum autem recusandae amet est autem assumenda."
    //         },
    //         "answer": {
    //           "EN": "Qui aut voluptates voluptatibus qui aliquam.",
    //           "DE": "Nesciunt enim molestias ex eos aut."
    //         }
    //       },
    //       {
    //         "id": 12,
    //         "question": {
    //           "EN": "Qui voluptatem dicta non quaerat est a.",
    //           "DE": "Quo rerum a omnis repudiandae necessitatibus."
    //         },
    //         "answer": {
    //           "EN": "Esse quam expedita repellat.",
    //           "DE": "Quisquam architecto aut provident quas aut saepe."
    //         }
    //       }
    //     ]
    //   }
    // ])

    const languages = ref([])
    const tasks = ref([])

    const openQuestionId = ref(null)

    const questions = computed(() => tasks.value.flatMap(t => t.questions))
    const openQuestion = computed(() => questions.value.find(q => q.id === openQuestionId.value) ?? null)

    function reload() {
      return Promise.all([
        fetcher('/api/languages').then(({data}) => languages.value = data?.data),
        fetcher('/api/tasks').then(({data}) => tasks.value = data?.data),
      ])
    }

    reload()

    function addQuestion(q) {
      for (let task of tasks.value) {
        if (task.id === q.task_id) {
          task.questions.push(q)
          break
        }
      }
    }

    function updateQuestion(q) {
      for (let task of tasks.value) {
        if (task.id === q.task_id) {
          for (let question of task.questions) {
            if (question.id === q.id) {
              question.question = q.question
              question.answer = q.answer
              break
            }
          }
          break
        }
      }

      fetchMessages()
    }

    return {
      languages,
      tasks: computed(() => tasks.value.map(task => ({
        firstQuestion: task.questions[0],
        otherQuestions: task.questions.slice(1),

        ...task,
      }))),

      openQuestionId,
      openQuestion,

      user: user,

      addQuestion,
      updateQuestion,
      reload,
    }
  }
}
</script>

<style scoped>
.table-wrapper {
  overflow: auto;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.section {
  margin-top: 48px;
}
</style>

<template>
  <div class="flex-grow-1 d-flex justify-content-center align-items-center">
    <div class="container-sm">
      <form class="login text-start p-4 pt-3" @submit.prevent="onSubmit">
        <h1 class="mb-4">Login</h1>

        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input v-model="username" type="text" class="form-control" id="username" required>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input v-model="password" type="password" class="form-control" id="password" required>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>

        <p v-if="state" class="mt-3 text-danger">
          {{ state }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import {isLoggedIn, setUser} from "@/lib/user";
import {ref} from 'vue'
import fetcher from "@/lib/fetcher";

export default {
  name: 'Login',

  setup() {
    const username = ref('')
    const password = ref('')

    const state = ref(null)

    async function onSubmit() {
      state.value = null

      await fetcher('/sanctum/csrf-cookie')

      const res = await fetcher('/api/login', {
        method: 'POST',
        body: JSON.stringify({
          username: username.value,
          password: password.value,
        })
      }).catch(() => state.value = 'An error occurred. Please try again.')

      if (res.ok) {
        setUser(res.data.data)
      } else {
        state.value = res.data?.message ?? 'An error occurred. Please try again.'
      }
    }

    return {
      isLoggedIn: isLoggedIn,

      username,
      password,
      state,

      onSubmit,
    }
  }
}
</script>

<style scoped>
.container-sm {
  max-width: 32rem;
}
.login {
  border: 3px solid #0084AD;
  border-radius: 0.25rem;
  max-width: 32rem;
  width: 100%;
}
</style>

<template>
  <div>
    <div class="mb-5">
      <h5 class="fw-bold">Admin Section</h5>
      <p class="">
        Here, you may download the data as a CSV or upload a CSV in the same format as the one that can be downloaded.
        Uploading a CSV will flush the corresponding data from the database and replace it with the data uploaded.
      </p>
    </div>

    <form class="mb-5" @submit.prevent="onSubmitUsersFile()">
      <h6 class="mb-3 fw-bold">Users & Languages</h6>
      <div class="mb-3">
        <a :href="usersDownloadUrl" class="btn btn-secondary">Download</a>
      </div>
      <div class="">
        <input type="file" required @change="e => usersFile = e.target.files?.[0] ?? null" />
        <button type="submit" class="btn btn-secondary">Upload</button>
      </div>
      <p v-if="usersState === 'success'" class="mt-3 text-success">Your CSV has been uploaded.</p>
      <p v-else-if="usersState" class="mt-3 text-danger">{{ usersState }}</p>
      <p v-else class="mt-3">Required Format: <code>Role (C/E/T/A),Firstname,Lastname,Country Code,Language Code,Username,Password</code></p>
    </form>

    <form class="mb-5" @submit.prevent="onSubmitTasksFile()">
      <h6 class="mb-3 fw-bold">Tasks</h6>
      <div class="mb-3">
        <a :href="tasksDownloadUrl" class="btn btn-secondary">Download</a>
      </div>
      <div class="">
        <input type="file" required @change="e => tasksFile = e.target.files?.[0] ?? null" />
        <button type="submit" class="btn btn-secondary">Upload</button>
      </div>
      <p v-if="tasksState === 'success'" class="mt-3 text-success">Your CSV has been uploaded.</p>
      <p v-else-if="tasksState" class="mt-3 text-danger">{{ tasksState }}</p>
      <p v-else class="mt-3">Required Format: <code>Day,Task</code></p>
    </form>

    <form class="" @submit.prevent="onSubmitQuestionsFile()">
      <h6 class="mb-3 fw-bold">Questions</h6>
      <div class="">
        <a :href="questionsDownloadUrl" class="btn btn-secondary">Download</a>
      </div>
    </form>
  </div>
</template>

<script>
import {user} from "@/lib/user";
import fetcher from "@/lib/fetcher";
import {computed, ref} from "vue";
import {API_URL} from "../lib/fetcher";

export default {
  setup(props, { emit }) {
    const usersDownloadUrl = computed(() => `${API_URL}/api/users/export`)
    const tasksDownloadUrl = computed(() => `${API_URL}/api/tasks/export`)
    const questionsDownloadUrl = computed(() => `${API_URL}/api/tasks/export-questions`)

    const usersFile = ref()
    const tasksFile = ref()

    const usersState = ref()
    const tasksState = ref()

    async function submitFile(path, file) {
      const form = new FormData()

      form.append('file', file)

      await fetcher('/sanctum/csrf-cookie')

      return await fetcher(path, {
        method: 'POST',
        body: form,
      })
    }

    async function onSubmitUsersFile() {
      const res = await submitFile('/api/users/import', usersFile.value).catch(() => usersState.value = 'An error occurred. Please try again.')

      if (res.ok) {
        usersState.value = 'success'
        setTimeout(() => usersState.value = null, 3000)

        emit('submitted', res.data.data)
      } else {
        usersState.value = res.data.message
      }
    }
    async function onSubmitTasksFile() {
      const res = await submitFile('/api/tasks/import', tasksFile.value).catch(() => tasksState.value = 'An error occurred. Please try again.')

      if (res.ok) {
        tasksState.value = 'success'
        setTimeout(() => tasksState.value = null, 3000)

        emit('submitted', res.data.data)
      } else {
        tasksState.value = res.data.message
      }
    }

    return {
      usersDownloadUrl,
      tasksDownloadUrl,
      questionsDownloadUrl,

      usersFile,
      tasksFile,

      usersState,
      tasksState,

      onSubmitUsersFile,
      onSubmitTasksFile,

      user,
    }
  }
}
</script>

<style scoped>
h5 + p {
  max-width: 52rem;
}
</style>

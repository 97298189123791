<template>
  <header>
    <div class="container-fluid py-3 d-flex flex-row justify-content-between text-white">
      <span class="fs-3 fw-bold lh-1">Mechatronics</span>

      <div>
        <div v-if="user">
          {{ user.username }} ({{ user.role }}) | <button class="p-0 border-0 bg-transparent text-white" @click="onLogout">Logout</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {setUser, user} from "@/lib/user";
import fetcher from "@/lib/fetcher";

export default {
  name: 'Navigation',

  setup() {
    async function onLogout() {
      await fetcher('/sanctum/csrf-cookie')

      await fetcher('/api/logout', {
        method: 'POST',
      })

      setUser(null)
    }

    return {
      user: user,

      onLogout,
    }
  }
}
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  background-color: #0084AD;
}
</style>

import {computed, ref} from "vue";
import fetcher from "./fetcher";

let internalMessages = ref([])

export function setMessages(messages) {
  internalMessages.value = messages
}

export async function fetchMessages() {
  return fetcher('/api/messages').then(res => {
    setMessages(res.data?.data ?? [])
  })
}

export const messages = computed(() => internalMessages.value)
